
import baseApi from "../../core/lib/base";
import { userSlice } from "../../store/slices/userSlice";
import { UserModel } from "../../types/user";
import toast from "react-hot-toast";
import { ResponseModel } from "../../core";
import { storage } from "../../utils";

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<ResponseModel<any>, any>({
            query: (payload) => ({
                method: "POST",
                url: "/api/v2/user/login",
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    console.log(data)
                    dispatch(userSlice.actions.setUser(data.user));
                    storage.setToken(data.access_token);
                } catch (error: any) {
                    console.log(error)
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        verifyOtp: builder.mutation<ResponseModel<any>, any>({
            query: (payload) => ({
                method: "POST",
                url: "/api/v2/user/verify-otp",
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } : any = await queryFulfilled;
                    toast.success("Account Verified");
                    dispatch(userSlice.actions.setUser(data.user));
                    storage.setToken(data.access_token);
                } catch (error: any) {
                }
            },
        }),

        signUp: builder.mutation<ResponseModel<any>, any>({
            query: (payload) => ({
                method: "POST",
                url: "/api/v2/user/register",
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error: any) {
                    console.log(error)
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        whoami: builder.query<ResponseModel<UserModel>, any>({
            query: () => ({
                method: "GET",
                url: "/api/v2/user/check",
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(userSlice.actions.setUser(data));
                } catch (error) {}
            },
        }),

    }),
});

export const {
    useLoginMutation,
    useWhoamiQuery,
    useSignUpMutation,
    useVerifyOtpMutation
} = authApi;
