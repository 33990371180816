import { useGetSingleTicketQuery} from "../../api/tickets";
import {useParams} from "react-router-dom";

export default function MySingleTicket() {
    const { id } = useParams();
    const { data, isLoading } = useGetSingleTicketQuery({ id: id ?? '' });
    return (
        <div className={'flex flex-col items-center justify-start py-10'}>
            {!isLoading ? !data ? <div>No ticket found.</div> :
                <div className={'flex w-full items-center md:w-11/12 p-4 flex-col md:flex-row gap-2 justify-center'}>
                    <img src={`https://quickchart.io/qr?text=${data.ticket_id}`} alt={data.ticket_id} className={'h-44 w-44 sm:w-96 sm:h-96 rounded-3xl'} />
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <p className={'text-gray-500 text-sm'}>Event Name</p>
                            <h1 className={'text-2xl text-primary font-semibold'}> {data.event.title} </h1>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Ticket</p>
                                <h1 className={'text-xl font-semibold'}> {data.pricing.type} </h1>
                            </div>
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Price</p>
                                <h1 className={'text-xl font-semibold'}> {data.pricing.price.toLocaleString()} {data.event.currency} </h1>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Booked At</p>
                                <h1 className={'text-xl font-semibold'}> {new Date(data.created_at).toLocaleDateString('en-US', {
                                    weekday: 'long', // 'Saturday'
                                    day: '2-digit', // '14'
                                    month: 'short', // 'Oct'
                                    year: 'numeric' // '2012'
                                })} </h1>
                            </div>
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Time</p>
                                <h1 className={'text-xl font-semibold'}> {new Date(data.created_at).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })} </h1>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Names</p>
                                <h1 className={'text-xl font-semibold'}> {data.names} </h1>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <div className="flex flex-col">
                                <p className={'text-gray-500 text-sm'}>Scanned</p>
                                <h1 className={'text-xl font-semibold'}> {data.scanned_at ? new Date(data.scanned_at).toLocaleDateString('en-US', {
                                    weekday: 'long', // 'Saturday'
                                    day: '2-digit', // '14'
                                    month: 'short', // 'Oct'
                                    year: 'numeric' // '2012'
                                }) : <div className={'text-green-500'}>Not Scanned</div>} </h1>
                            </div>
                        </div>
                    </div>
                </div>
                : <div>Loading</div>}
        </div>
    );
}