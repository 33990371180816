import {useVerifyAuth} from "../utils/checkUser";
import {ReactNode} from "react";

export default function ProtectedContainer(props: { children: ReactNode, }) {
    const { data, isLoading } = useVerifyAuth();

    return (
        <>
            {data ? props.children : isLoading ?
                <div className={'w-full flex justify-center items-center text-darker'}>Loading</div>
                : null}
        </>
    )
}