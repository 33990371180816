import {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useAppSelector} from "../../../store";
import {useLoginMutation} from "../../../api/auth";
import AnimatedContainer from "../../../container/animatedContainer";
import {useForm} from "react-hook-form";
import {motion} from "framer-motion";
import {HiOutlineExclamationCircle} from "react-icons/hi2";

export const Login = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userState);
    const [onLogin, { isSuccess, isLoading, isError, error }] = useLoginMutation();

    const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: {
        username: '', password: ''
    }});

    if(user) {
        navigate('/');
    }

    useEffect(() => {
        if (isSuccess) {
            navigate('/');
        }

    }, [isSuccess, navigate]);

    // @ts-ignore
    return (
        <AnimatedContainer>
            <main className="Container">
                <div className="w-full">
                    <div className="w-11/12 m-5 max-w-[1000px] flex flex-row mx-auto shadow-md mt-10">
                        <div
                            style={{
                                background: `url('https://wallpapercave.com/wp/wp2349397.jpg')`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                            className="w-1/2 hidden md:block"
                        >
                            <div className="w-full h-full flex backdrop-blur-md flex-col justify-center items-center dark-bg p-10">
                                <p className='text-[40px] text-white font-bold'>Welcome Back,</p>
                                <p className='text-[20px] text-white'>Sign in to your account to access all the features and services we offer.</p>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onLogin)} className="w-full md:w-1/2 p-5 md:px-10">
                            <h2 className="font-black mb-5 text-[30px] lg:text-[40px] text-Maincolor text-center">
                                Sign In
                            </h2>
                            {isError && <motion.div
                                initial={{ y: '-10px', opacity: 0.5 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="py-1 border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-red-500 bg-red-200 text-red-500">
                                <HiOutlineExclamationCircle/>

                                {error ? (error as any).data?.message : null}
                            </motion.div>}
                            <div className="">
                                <label htmlFor={'email'}>Email or Username</label>
                                <input type={'email'} {...register('username', { required: 'Username is required' })} placeholder={'ex. johndoe@mail.com'}
                                       className={'bg-gray-100 px-5 py-3 w-full focus:outline-none rounded'}/>
                                {errors.username?.message && <p className={'text-sm my-0.5 text-red-500'}>{errors.username.message}</p>}
                            </div>
                            <div className="">
                                <label htmlFor={'password'}>Password</label>
                                <input type={'password'} {...register('password', { required: 'Password is required' })} placeholder={'Enter your password'}
                                       className={'bg-gray-100 px-5 py-3 w-full focus:outline-none rounded'}/>
                                {errors.password?.message && <p className={'text-sm my-0.5 text-red-500'}>{errors.password.message}</p>}
                            </div>

                            <button className={'bg-darker cursor-pointer flex justify-center items-center my-2 mt-5 text-white px-5 py-3 w-full focus:outline-none rounded'}>
                                {isLoading ? 'Loading...' : 'Sign In'}
                            </button>


                            <div className="p-2 flex flex-wrap mt-10 gap-2 justify-center items-center">
                                Don't have an account?{' '}
                                <Link
                                    className="text-Maincolor font-semibold text-xl"
                                    to="/signup"
                                >
                                    Sign Up
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </AnimatedContainer>
    );
};

export default Login;
