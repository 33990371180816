import {
    combineReducers,
    configureStore,
} from '@reduxjs/toolkit';
import baseApi from '../core/lib/base';
import config from '../config';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import { userSlice } from './slices/userSlice';
import { authApi } from '../api/auth/index';

const rootReducer = combineReducers({

    userState: userSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
});

const middlewares: any = [baseApi.middleware];

export const store = configureStore({
    reducer: rootReducer,
    devTools: config.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(middlewares),
});

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
