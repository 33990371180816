import {Outlet, useLocation} from "react-router-dom";
import MainHeader from "../components/mainHeader";
import MainFooter from "../components/mainFooter";
import { useEffect } from "react";

export default function HomeContainer() {
    return (
        <div className={'flex flex-col justify-between h-screen'}>
            <ScrollToTop />
            <MainHeader />
            <Outlet />
            <MainFooter />
        </div>
    )
}

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
