import {useGetMyTicketsQuery} from "../../api/tickets";
import {Link} from "react-router-dom";

export default function MyTickets() {
    const { data, isLoading } = useGetMyTicketsQuery({ pageSize: 10 });
    return (
        <div className={'flex flex-col items-center justify-start py-10'}>
            <h1 className={'w-11/12 py-5 text-2xl font-bold text-primary'}>My Tickets</h1>
            {!isLoading ? data?.list.length === 0 ? <div>No tickets yet.</div> : data?.list.map((item, index) => <Link
                key={index}
                to={`/ticket/${item.ticket_id}`}
                className="p-5 w-11/12 my-2 bg-gray-100 flex flex-wrap sm:flex-wrap cursor-pointer gap-3 justify-start rounded-xl">
                <div className="p-3 bg-gray-300 w-full md:w-28 h-28" key={index} style={{
                    background: `url('${item.event.image}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}></div>
                <div className="flex flex-col items-start">
                    <h1 className={'text-3xl font-bold text-primary'}>{item.event.title}</h1>
                    <p>{
                        new Date(item.event.date).toLocaleDateString('en-US', {
                            weekday: 'long', // 'Saturday'
                            day: '2-digit', // '14'
                            month: 'short', // 'Oct'
                            year: 'numeric' // '2012'
                        })
                    }</p>
                    <p>{item.event.location}, {item.event.country}</p>
                </div>
            </Link>): <div>Loading</div>}
            <div className="my-5 flex flex-row gap-2 justify-center w-11/12">
                {data ? data?.list.length > 3 && [1,1,1].map((_, index) => <div className={`p-2 w-10 h-10 ${index === 0 ? 'bg-gray-400 text-white' : 'text-white cursor-pointer  bg-primary'} text-center rounded-full`}>{index + 1}</div>) : null }
            </div>
        </div>
    );
}