import {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useAppSelector} from "../../../store";
import {useSignUpMutation, useVerifyOtpMutation} from "../../../api/auth";
import AnimatedContainer from "../../../container/animatedContainer";
import {useForm} from "react-hook-form";
import {motion} from "framer-motion";
import {HiOutlineExclamationCircle} from "react-icons/hi2";

export const SignUp = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userState)
    const [onLogin, { isSuccess, isLoading, isError, error }] = useSignUpMutation();
    const [verify, verifyOptions] = useVerifyOtpMutation();

    const [step, setStep] = useState< 1 | 2>(1);

    const otpForm = useForm({
        defaultValues: {
            otp: ''
        }
    });

    const { register, handleSubmit, formState: { errors }, watch } = useForm({ defaultValues: {
            username: '', password: '', email: '', phoneNumber: '', name: ''
    }});

    useEffect(() => {
        if (isSuccess) {
            setStep(2);
        }

        if(verifyOptions.isSuccess) {
            navigate('/');
        }

    }, [isSuccess, verifyOptions.isSuccess]);

    if(user) {
        navigate('/')
    }

    return (
        <AnimatedContainer>
            <main className="Container">
                <div className="w-full">
                    <div className="w-11/12 m-5 max-w-[1000px] flex flex-row mx-auto shadow-md mt-10">
                        <div
                            style={{
                                background: `url('https://wallpapercave.com/wp/wp2349397.jpg')`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                            className="w-1/2 hidden md:block"
                        >
                            <div className="w-full h-full flex backdrop-blur-md flex-col justify-center items-center dark-bg p-10">
                                <p className="text-[40px] text-white font-bold">
                                    Join Us Today!
                                </p>
                                <p className="text-[20px] text-white">
                                    Create your account to unlock a world of exclusive benefits and features.
                                </p>
                            </div>
                        </div>
                        {step === 1 && <form onSubmit={handleSubmit((data) => onLogin({
                            ...data,
                            phoneNumber: `2507${data.phoneNumber}`
                        }))} className="w-full md:w-1/2 p-5 md:px-10">
                            <h2 className="font-black mb-5 text-[30px] lg:text-[40px] text-Maincolor text-center">
                                Sign Up
                            </h2>
                            {isError && <motion.div
                                initial={{y: '-10px', opacity: 0.5}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 0.5}}
                                className="py-1 border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-red-500 bg-red-200 text-red-500">
                                <HiOutlineExclamationCircle/>

                                {error ? (error as any).data?.message : 'Something went wrong, Try again later'}
                            </motion.div>}
                            <div className="flex flex-col gap-2 col-span-2">
                                <label htmlFor="email">Email</label>
                                <input type="email"
                                       {...register('email', {required: 'Email is required'})}
                                       className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                       placeholder={'Enter your email address'}/>
                                {errors.email && <p className={'text-red-500 text-sm'}>{errors.email.message}</p>}
                            </div>
                            <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                <label htmlFor="email">Names</label>
                                <input type="name"
                                       {...register('name', {required: 'Name is required'})}
                                       className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                       placeholder={'Enter your full names'}/>
                                {errors.name && <p className={'text-red-500 text-sm'}>{errors.name.message}</p>}
                            </div>
                            <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                <label htmlFor="email">Username</label>
                                <input type="name"
                                       {...register('username', {required: 'Username is required'})}
                                       className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                       placeholder={'Enter your username'}/>
                                {errors.name && <p className={'text-red-500 text-sm'}>{errors.name.message}</p>}
                            </div>
                            <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                <label htmlFor="email">Phone number</label>
                                <div className="bg-gray-100 flex flex-row gap-1 px-3 py-2 w-full">
                                    <p>+2507</p>
                                    <input type="tel"
                                           {...register('phoneNumber', {
                                               required: 'Phone number is required',
                                               pattern: {
                                                   value: /^[0-9]{8}$/,
                                                   message: 'Phone number invalid'
                                               }
                                           })}
                                           className={'bg-transparent w-[80%]     focus:outline-none rounded'}
                                           placeholder={'Enter your phone number'}/>
                                </div>
                                {errors.phoneNumber &&
                                    <p className={'text-red-500 text-sm'}>{errors.phoneNumber.message}</p>}
                            </div>
                            <div className="">
                                <label htmlFor={'password'}>Password</label>
                                <input type={'password'} {...register('password', {required: 'Password is required'})}
                                       placeholder={'Enter your password'}
                                       className={'bg-gray-100 px-5 py-3 w-full focus:outline-none rounded'}/>
                                {errors.password?.message &&
                                    <p className={'text-sm my-0.5 text-red-500'}>{errors.password.message}</p>}
                            </div>

                            <button
                                className={'bg-darker cursor-pointer flex justify-center items-center my-2 mt-5 text-white px-5 py-3 w-full focus:outline-none rounded'}>
                                {isLoading ? 'Loading...' : 'Sign Up'}
                            </button>
                            <div className="p-2 flex flex-wrap mt-10 gap-2 justify-center items-center">
                                Already have an account?{' '}
                                <Link
                                    className="text-Maincolor font-semibold text-xl"
                                    to="/login"
                                >
                                    Sign In
                                </Link>
                            </div>
                        </form> }
                        {step === 2 && <form onSubmit={otpForm.handleSubmit(verify)} className="w-full md:w-1/2 p-5 md:px-10">
                            <h2 className="font-black mb-5 text-[30px] lg:text-[40px] text-Maincolor text-center">
                                Verify Account
                            </h2>
                            <p>We sent an OTP to *******{watch('phoneNumber').substring(watch('phoneNumber').length - 3)}</p>
                            {isError && <motion.div
                                initial={{y: '-10px', opacity: 0.5}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 0.5}}
                                className="py-1 border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-red-500 bg-red-200 text-red-500">
                                <HiOutlineExclamationCircle/>

                                {error ? (error as any).data?.message : 'Something went wrong, Try again later'}
                            </motion.div>}
                            <div className="flex flex-col gap-2 col-span-2">
                                <label htmlFor="email">OTP</label>
                                <input type="email"
                                       {...otpForm.register('otp', {required: 'OTP is required'})}
                                       className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                       placeholder={'Enter your received otp'}/>
                                {otpForm.formState.errors.otp && <p className={'text-red-500 text-sm'}>{otpForm.formState.errors.otp.message}</p>}
                            </div>

                            <button
                                className={'bg-darker cursor-pointer flex justify-center items-center my-2 mt-5 text-white px-5 py-3 w-full focus:outline-none rounded'}>
                                {isLoading ? 'Loading...' : 'Verify'}
                            </button>
                        </form>}
                    </div>
                </div>
            </main>
        </AnimatedContainer>
    );
};

export default SignUp;
