import React, { ReactNode } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router";
import {AnimatePresence} from "framer-motion";
import {Provider} from "react-redux";
import { store } from "./store";

type AppProviderProps = {
    children: ReactNode;
};

function AppProvider({ children }: AppProviderProps) {
    return (
        <Provider store={store}>
            <BrowserRouter>
                {children}
            </BrowserRouter>
        </Provider>
    )
}

function App() {
  return (
    <div className="min-h-screen">
      <AppProvider>
          <AnimatePresence>
              <AppRouter />
          </AnimatePresence>
      </AppProvider>
    </div>
  );
}

export default App;
